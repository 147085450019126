import React from "react"

// This component can be used in child views to change global context
class ContextUpdater extends React.Component {
  //constructor(props) {
  //  super(props);
	//props.context.set(props.newContext)
  //}

  componentDidMount() {
    //console.log(this.props.context);
    //console.log(this.props.newContext);
	this.props.context.set(this.props.newContext)
  }

  render() {
    return null;
  }
}

export default ContextUpdater
